<template>
  <v-card class="rounded-xl mb-10">
    <v-card-actions class="justify-space-between">
      <!--hack solution-->
      <div v-if="contentType !== 'Music'"></div>
      <vue-plyr v-if="contentType === 'Music'" class="left">
        <audio
          controls
          playsinline
        >
      <source
        :src="fileURL"
        :type="mimeType"
      />
        </audio>
      </vue-plyr>
      <favourite-button
        :favourites-count="favouritesCount"
        :is-favourited="isFavourited"
        show-count
        @toggle="toggleFavourited"
      />
    </v-card-actions>
    <v-img
      :src="imageURL"
      height="500"
    >
  <vue-plyr v-if="contentType === 'Video'">
  <video
    controls
    playsinline
  >
    <source
      :src="fileURL"
      :type="mimeType"
    />
  </video>
  </vue-plyr>
    <!--insert audio or video player-->
    </v-img>
  </v-card>
</template>

<script>
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';

export default {
  name: 'NFTListingImage',
  components: {
    VuePlyr,
  },
  props: {
    imageURL: {
      type: String,
      required: true,
      default: () => (''),
    },
    contentType: {
      type: String,
      required: true,
      default: () => ('Art'),
    },
    fileURL: {
      type: String,
      required: true,
      default: () => (''),
    },
    mimeType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isFavourited: false,
    favouritesCount: 0,
  }),
  methods: {
    toggleFavourited() {
      this.isFavourited = !this.isFavourited;
    },
    clickLike() {

    },
  },
};
</script>
